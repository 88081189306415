// extracted by mini-css-extract-plugin
export var carouselContainer = "H_ww d_w d_H d_bf d_Z";
export var carouselContainerCards = "H_wx H_ww d_w d_H d_bf d_Z";
export var carouselContainerSides = "H_wy d_w d_H d_Z";
export var prevCarouselItem = "H_wz d_w d_H d_0 d_k";
export var prevCarouselItemL = "H_wB H_wz d_w d_H d_0 d_k";
export var moveInFromLeft = "H_wC";
export var prevCarouselItemR = "H_wD H_wz d_w d_H d_0 d_k";
export var moveInFromRight = "H_wF";
export var selectedCarouselItem = "H_wG d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "H_wH H_wG d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "H_wJ H_wG d_w d_H d_Z d_bf";
export var nextCarouselItem = "H_wK d_w d_H d_0 d_k";
export var nextCarouselItemL = "H_wL H_wK d_w d_H d_0 d_k";
export var nextCarouselItemR = "H_wM H_wK d_w d_H d_0 d_k";
export var arrowContainer = "H_wN d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "H_wP H_wN d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "H_wQ H_wP H_wN d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "H_wR H_wN d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "H_wS";
export var nextArrowContainerHidden = "H_wT H_wR H_wN d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "H_kG d_0";
export var prevArrow = "H_wV H_kG d_0";
export var nextArrow = "H_wW H_kG d_0";
export var carouselIndicatorContainer = "H_wX d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "H_wY d_w d_bz d_bF";
export var carouselText = "H_wZ d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "H_w0 H_wZ d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "H_w1 d_b7";
export var carouselIndicator = "H_w2 H_w1 d_b7";
export var carouselIndicatorSelected = "H_w3 H_w1 d_b7";
export var arrowsContainerTopRight = "H_w4 d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "H_w5 d_0 d_bl d_bC";
export var arrowsContainerSides = "H_w6 d_0 d_bl d_bC";
export var smallArrowsBase = "H_w7 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "H_w8 H_w7 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "H_w9 H_w8 H_w7 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "H_xb H_w7 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "H_xc H_xb H_w7 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "H_p9";
export var iconSmall = "H_xd";
export var multipleWrapper = "H_xf d_bC d_bF d_bf";
export var multipleImage = "H_xg d_bC";
export var sidesPrevContainer = "H_xh H_w8 H_w7 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "H_xj H_w8 H_w7 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
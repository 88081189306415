// extracted by mini-css-extract-plugin
export var alignDiscLeft = "p_q8 d_fp d_bG d_dv";
export var alignLeft = "p_qD d_fp d_bG d_dv";
export var alignDiscCenter = "p_q9 d_fq d_bD d_dw";
export var alignCenter = "p_bP d_fq d_bD d_dw";
export var alignDiscRight = "p_rb d_fr d_bH d_dx";
export var alignRight = "p_qF d_fr d_bH d_dx";
export var footerContainer = "p_rc d_dW d_bW";
export var footerContainerFull = "p_rd d_dT d_bW";
export var footerHeader = "p_kf d_kf";
export var footerTextWrapper = "p_rf d_w";
export var footerDisclaimerWrapper = "p_km d_km d_ck";
export var badgeWrapper = "p_rg d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "p_rh d_bz d_bJ d_bN d_bL";
export var wide = "p_rj d_cy";
export var right = "p_rk d_bK";
export var line = "p_fk d_fl d_cv";
export var badgeDisclaimer = "p_rl d_bC d_bP d_bJ";
export var badgeContainer = "p_rm d_bz d_bH d_bP";
export var badge = "p_rn";
export var padding = "p_rp d_c7";
export var end = "p_rq d_bH";
export var linkWrapper = "p_rr d_dB";
export var link = "p_mZ d_dB";
export var colWrapper = "p_rs d_cx";
export var consent = "p_f d_f d_bC d_bP";
export var disclaimer = "p_rt d_bz d_bJ";
export var media = "p_rv d_bx d_dy";
export var itemRight = "p_rw";
export var itemLeft = "p_rx";
export var itemCenter = "p_ry";
export var exceptionWeight = "p_rz s_st";
// extracted by mini-css-extract-plugin
export var alignLeft = "D_qD d_fp d_bG d_dv";
export var alignCenter = "D_bP d_fq d_bD d_dw";
export var alignRight = "D_qF d_fr d_bH d_dx";
export var testimonialsContainer = "D_v2 d_dW";
export var testimonialsContainerFull = "D_v3 d_dT";
export var testimonialsMainHeader = "D_jw d_jw d_w d_cw";
export var testimonialsComponentParagraph = "D_jy d_jy";
export var testimonialsComponentSmall = "D_jx d_jx";
export var testimonialsComponentsWrapper = "D_js d_js d_y d_w d_cv d_b3 d_b5";
export var testimonialsComponentsWrapperDesign2 = "D_jt d_jt d_y d_w d_cv d_b3 d_b5 d_bC";
export var testimonialsComponentsWrapperDesign3 = "D_jv d_jv d_y d_w d_H d_b5 d_bT d_bz d_bJ d_bP d_bD";
export var testimonialsBackgroundColor = "D_v4 d_d7";
export var colEqualHeight = "D_v5 d_bz";
export var testimonialsColumnWrapper = "D_jz d_jz d_b3";
export var testimonialsImageWrapper = "D_jp d_jp d_w d_Z";
export var testimonialsImageWrapperDesign2 = "D_jq d_jq d_r d_Z d_bx";
export var testimonialsImageWrapperDesign3 = "D_jr d_jr d_Z d_bz";
export var design2TextWrapper = "D_v6 d_bC d_bP d_bJ";
export var design3 = "D_v7 d_cD d_H";
export var imageDesign2 = "D_v8";
export var SmallSmall = "D_qz s_qz s_r2 s_sp";
export var SmallNormal = "D_qB s_qB s_r2 s_sq";
export var SmallLarge = "D_qC s_qC s_r2 s_sm";
export var exceptionWeight = "D_rz s_st";
// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "n_qG d_bD";
export var datasheetRowWrapper = "n_lt d_lt d_bK";
export var datasheetLeftWrapper = "n_qH d_bz d_bP";
export var datasheetWrapperFull = "n_qJ d_cD";
export var datasheetWrapperFullLeft = "n_pf d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "n_mS d_lv";
export var contentWrapperCenter = "n_qK d_lz";
export var datasheetLeftWrapperCenter = "n_qL d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "n_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "n_qM d_lw";
export var datasheetFooter = "n_ly d_ly";
export var alignLeft = "n_qD d_dv";
export var alignCenter = "n_bP d_dw";
export var alignRight = "n_qF d_dx";
export var datasheetInnerWrapperNewLeft = "n_qN d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "n_qP d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "n_qQ d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "n_qR d_bK";
export var datasheetSubtitle = "n_qS d_cx";
export var tableRow = "n_qT";
export var cell = "n_qV";
export var tableRowWrapper = "n_qW d_w d_dv";
export var tableHeadingCell = "n_qX";
export var tableHeading = "n_qY";
export var tableRowStriped = "n_qZ";
export var tableRowHeading = "n_q0";
export var dataTable = "n_q1 d_w";
export var imageWrapper = "n_qy d_fg d_Z";
export var imageWrapperFull = "n_q2 d_H d_w d_bf d_Z";
export var imageWrapperIcon = "n_q3";
export var titleMargin = "n_q4 d_cs";
export var datasheetInnerInnerWrapper = "n_q5 d_w";
export var hasLabels = "n_q6";
export var label = "n_q7";
export var SmallSmall = "n_qz s_qz s_r2 s_sp";
export var SmallNormal = "n_qB s_qB s_r2 s_sq";
export var SmallLarge = "n_qC s_qC s_r2 s_sm";
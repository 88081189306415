// extracted by mini-css-extract-plugin
export var alignLeft = "x_qD d_bG";
export var alignCenter = "x_bP d_bD";
export var alignRight = "x_qF d_bH";
export var textAlignLeft = "x_vn";
export var textAlignCenter = "x_vp";
export var textAlignRight = "x_vq";
export var hoursInnerWrapperAlt = "x_vr d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "x_vs d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "x_vt d_dw";
export var titleMargin = "x_q4 d_cw";
export var hoursInnerInnerWrapper = "x_vv d_cz";
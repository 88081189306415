// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_rK d_gv d_cs";
export var heroHeaderCenter = "r_gw d_gw d_cs d_dw";
export var heroHeaderRight = "r_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "r_rL d_gr d_cw";
export var heroParagraphCenter = "r_gs d_gs d_cw d_dw";
export var heroParagraphRight = "r_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "r_rM d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "r_rN d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "r_rP d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "r_rQ d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "r_rR d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "r_rS s_rS";
export var heroExceptionNormal = "r_rT s_rT";
export var heroExceptionLarge = "r_rV s_rV";
export var Title1Small = "r_rW s_rW s_r2 s_r3";
export var Title1Normal = "r_rX s_rX s_r2 s_r4";
export var Title1Large = "r_rY s_rY s_r2 s_r5";
export var BodySmall = "r_rZ s_rZ s_r2 s_sl";
export var BodyNormal = "r_r0 s_r0 s_r2 s_sm";
export var BodyLarge = "r_r1 s_r1 s_r2 s_sn";
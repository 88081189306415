// extracted by mini-css-extract-plugin
export var socialContainer = "z_vD";
export var instagramLink = "z_vF";
export var socialWrapperLeft = "z_nX d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "z_vG";
export var socialContentContainerFull = "z_vH";
export var instagramContainer = "z_vJ";
export var twitterContainer = "z_vK";
export var facebookContainer = "z_vL";
export var socialErrorContainer = "z_vM";
export var facebookContainerWide = "z_vN";
export var twitterContainerWide = "z_vP";
export var socialParagraphCenter = "z_vQ";
export var instaWrapper = "z_vR";
export var SubtitleSmall = "z_qd s_qd s_r2 s_sd";
export var SubtitleNormal = "z_qf s_qf s_r2 s_sf";
export var SubtitleLarge = "z_qg s_qg s_r2 s_sg";
export var textLeft = "z_dv";
export var textCenter = "z_dw";
export var textRight = "z_dx";
// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "v_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "v_fR d_fR d_bz d_bJ";
export var menuDesign6 = "v_s7 d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "v_s8 d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "v_s9 d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "v_tb d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "v_tc d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "v_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "v_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "v_td";
export var navbarItem = "v_nb d_bx";
export var navbarLogoItemWrapper = "v_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "v_tf d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "v_tg d_gd d_by d_Z d_bs";
export var burgerToggle = "v_th d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "v_tj d_gd d_by d_Z d_bs";
export var burgerInput = "v_tk d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "v_tl d_f3 d_w d_H";
export var burgerLine = "v_tm d_f1";
export var burgerMenuDesign6 = "v_tn d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "v_tp d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "v_tq d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "v_tr d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "v_ts d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "v_tt d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "v_tv d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "v_tw d_bC d_bP";
export var compact = "v_tx";
export var navDivided = "v_ty";
export var staticBurger = "v_tz";
export var menu = "v_tB";
export var navbarDividedLogo = "v_tC";
export var nav = "v_tD";
export var fixed = "v_tF";